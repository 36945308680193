import React, { useState, useRef } from "react"
import "./styles.scss"
import useFormValues from "../enhancers/useFormValues"
import { sendJoinBeta } from "../../API/hanlders/sendJoinBeta"
import { validateEmail } from "../ContactUsForm"
import ReCAPTCHA from "react-google-recaptcha"

export default function MailForm({ buttonText, input, successSubmitFormText, theme = "" }) {
    const [submitted, setSubmitting] = useState(false)
    const reCaptchaRef = useRef()

    const {
        values,
        change,
        errors: { errors: errorsCollection },
        setErrors,
    } = useFormValues({ email: "", recaptcha_token: "" })

    function onSubmit(event) {
        event.preventDefault()
        if (validateEmail(values.email)) {
            reCaptchaRef.current
                .execute()
                .then((recaptcha_token) => {
                    setSubmitting(true)

                    sendJoinBeta({ ...values, recaptcha_token })
                        .then((response) => {
                            setSubmitting(true) // show success message
                        })
                        .catch((error) => {
                            console.error({ error }) // TODO: handle error message
                        })
                })
                .catch((error) => {
                    console.log({ error })
                })
        } else {
            setErrors({ email: "Please enter a valid email address" })
        }
    }

    return (
        <div className={`mail-form-wrapper ${theme}`}>
            {submitted ? (
                <p className={"mail-form-submitted-text"}>{successSubmitFormText}</p>
            ) : (
                <form onSubmit={onSubmit} className={"form-wrapper"} noValidate>
                    <div>
                        <input
                            {...input}
                            className={"input-underlined"}
                            value={values.email}
                            onChange={change}
                            name={"email"}
                            type="email"
                        />
                        {errorsCollection["email"] && <p className={"invalid-text"}>{errorsCollection["email"]}</p>}
                    </div>
                    <button type={"submit"} className={"a-button"}>
                        {buttonText}
                    </button>
                </form>
            )}

            <ReCAPTCHA
                sitekey={"6LcCcNEZAAAAALc_6po5xxbBYds15Ow1T8elxVcB"}
                render="explicit"
                size="invisible"
                ref={reCaptchaRef}
            />
        </div>
    )
}
