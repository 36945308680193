import React, { useEffect } from "react"
import axios from "axios"
import reactParser from "html-react-parser"
import { Link } from "gatsby"
import dayJs from "dayjs"

import "./styles.scss"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../../analytics"

function parseHTML(html) {
    return reactParser(html)
}

function AuthorContainer({ authorHref, created_at }) {
    const [author, setAuthor] = React.useState({})

    React.useEffect(() => {
        if (authorHref) {
            axios({
                method: "GET",
                url: authorHref,
            })
                .then((response) => {
                    const { data } = response

                    setAuthor(data)
                })
                .catch((e) => {
                    console.log({ e })
                })
        }
    }, [authorHref])

    return <AuthorUI {...author} created_at={created_at} />
}

function CreatedAt({ created_at }) {
    const formattedData = dayJs(created_at).format("MMMM DD, YYYY")

    return <p className="blog-footer__created-at">{formattedData}</p>
}

function AuthorUI({ name, created_at, avatar_urls }) {
    return (
        <div className="blog-footer">
            {/*<div className="blog-footer__author-avatar-wrapper">*/}
            {/*<div className="blog-footer__author-avatar-placeholder">*/}
            {/*    <AuthorAvatar avatar_url={avatar_urls} />*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className="blog-footer__author-info">
                <CreatedAt created_at={created_at} />
            </div>
        </div>
    )
}

function AuthorAvatar({ avatar_url }) {
    const [avatar, setAvatar] = React.useState("")

    React.useEffect(() => {
        if (!avatar && avatar_url) {
            setAvatar(Object.values(avatar_url)[0])
        }
    }, [avatar_url])

    return <img src={avatar_url} alt="" />
}

function BlogPreview({ prevImage }) {
    const [sourceImage, setPrevImage] = React.useState("")
    React.useEffect(() => {
        if (!sourceImage && prevImage) {
            axios({
                method: "GET",
                url: prevImage,
            })
                .then((response) => {
                    const { data } = response
                    setPrevImage(data.source_url)
                })
                .catch((e) => {
                    console.log({ e })
                })
        }
    }, [prevImage])

    return sourceImage ? (
        <div className={"blog-card-container-image"}>
            <div className="blog-card__image-wrapper">
                <img src={sourceImage} alt="" />
            </div>
        </div>
    ) : null
}

function BlogCard({ title, excerpt, _links, date, link, type }) {
    const handleLinkClick = (e) => {
        e.preventDefault()
        let analyticsCategory = ""
        switch (type) {
            case "Calendar AI": {
                analyticsCategory = AnalyticsEventCategories.CALENDAR_AI_PAGE
                break
            }
            case "Call AI": {
                analyticsCategory = AnalyticsEventCategories.CALLS_AI_PAGE
                break
            }
            case "Mail AI": {
                analyticsCategory = AnalyticsEventCategories.SYNC_AI_MAIL_PAGE
                break
            }
            default:
                break
        }

        emitAnalyticsEvent("Click Blog Post", analyticsCategory)
        window.open(link, "_blank")
    }
    
    const prevImageInBlog = _links["wp:featuredmedia"] ? _links["wp:featuredmedia"][0].href : null

    return (
        <div className={"blog-card"} onClick={handleLinkClick}>
            <BlogPreview prevImage={prevImageInBlog} />
            <div className="blog-card__body">
                <p className="blog-title">{reactParser(title.rendered)}</p>
                <p className="blog-description">{parseHTML(excerpt.rendered).slice(0, 200)}</p>
            </div>

            <AuthorContainer created_at={date} authorHref={_links.author[0].href} />
        </div>
    )
}

export default function BlogSection(props) {
    const [blogPosts, setBlogPosts] = React.useState([])

    useEffect(() => {
        axios({
            method: "GET",
            url: "https://www.sync.ai/wp-json/wp/v2/posts",
        })
            .then((response) => {
                const { data } = response

                setBlogPosts(data.slice(0, 3))
            })
            .catch((e) => {
                console.log({ e })
            })
    }, [])

    return (
        <div className={"blog-section-container"}>
            <div>
                <p className="blog-section-title text-center">What’s new at Sync.AI</p>
            </div>
            <div className={"blog-row"}>
                {blogPosts.map((data, i) => (
                    <BlogCard {...data} type={props.type} key={i} />
                ))}
            </div>
        </div>
    )
}
