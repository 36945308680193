import axios from "axios"
import path from "../path"

export function sendJoinBeta(data) {
    return axios({
        url: path.joinBeta(),
        data: JSON.stringify(data),
        method: "POST",
    })
}
