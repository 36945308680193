import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
// sections
import Home from "../components/ProductSetcionsTemplate/Home"
import Benefits from "../components/ProductSetcionsTemplate/Benefits"
import Content from "../components/ProductSetcionsTemplate/Content"
import DownloadAppBlock from "../components/ProductSetcionsTemplate/DownloadAppBlock"

import ClientsFeedback from "../components/ClientsFeedback"
import BlogSection from "../components/BlogSection"
import SEO from "../components/seo"

import { emitAnalyticsEvent } from "../analytics"

export default function Product({
    data: { product, header_device_prev_image, benefits_images, content_images, download_app_image },
}) {
    const {
        data: {
            header_section,
            benefits_section,
            content_section,
            feedback_section,
            download_app_section,
            tabTitle,
            description,
            keywords,
            PRIMARY_COLOR_THEME,
            title,
            ANALYTICS_CATEGORY,
        },
    } = product

    useEffect(() => {
        emitAnalyticsEvent("Page view", ANALYTICS_CATEGORY)
    }, [])

    return (
        <Layout>
            <SEO title={tabTitle} description={description} keywords={keywords} />
            <Home {...header_section} theme={PRIMARY_COLOR_THEME} image={header_device_prev_image.publicURL} />
            <Benefits {...benefits_section} iconsSrc={benefits_images.edges} />
            <Content {...content_section} imageStore={content_images.edges} theme={PRIMARY_COLOR_THEME} />
            <ClientsFeedback {...feedback_section} theme={PRIMARY_COLOR_THEME} />
            <DownloadAppBlock {...download_app_section} theme={PRIMARY_COLOR_THEME} image={download_app_image} />
            <BlogSection type={title} />
        </Layout>
    )
}

export const query = graphql`
    query ProductPage(
        $slug: String!
        $homePreviewImageSourceDir: String!
        $benefitsSourceDir: String!
        $contentSourceDir: String!
        $downloadAppSourceDir: String!
    ) {
        product(fields: { slug: { eq: $slug } }) {
            data {
                PRIMARY_COLOR_THEME
                title
                description
                tabTitle
                keywords
                ANALYTICS_CATEGORY
                header_section {
                    component_props {
                        buttons {
                            label
                            href
                            type
                            actionType
                            categoryType
                        }
                        successSubmitFormText
                        buttonText
                        input {
                            placeholder
                        }
                    }
                    component_type
                    description
                    preview_device {
                        animation_type
                        animation_json
                    }
                    title
                }

                benefits_section {
                    title
                    items {
                        description
                        icon_name
                        title
                    }
                }

                content_section {
                    items {
                        CSS_MODIFIER_CONTAINER_CLASS_NAME
                        description
                        preview_images {
                            ANIMATION_ACTIVE_CLASS
                            INITIAL_CSS_CONTAINER_CLASSNAME
                            IMAGES_CONTAINER_CLASSNAME
                            animation_type
                            animation_json
                            images {
                                image_name
                                INITIAL_CSS_CLASSNAME
                                CSS_SCALE_MODIFIER
                                CSS_CLASSNAME
                            }
                        }
                        title
                    }
                }

                feedback_section {
                    title
                    items {
                        author {
                            avatar
                            position
                            name
                            company_name
                        }
                        rating
                        message
                        id
                    }
                }

                download_app_section {
                    title
                    subtitle
                    preview_image_css_animation
                    description
                    component_type
                    component_props {
                        buttonText
                        successSubmitFormText
                        input {
                            placeholder
                        }
                        buttons {
                            href
                            label
                            type
                            actionType
                            categoryType
                        }
                    }
                }
            }
        }

        header_device_prev_image: file(
            sourceInstanceName: { eq: "products_images" }
            relativeDirectory: { eq: $homePreviewImageSourceDir }
        ) {
            publicURL
        }

        benefits_images: allFile(
            filter: { sourceInstanceName: { eq: "products_images" }, relativeDirectory: { eq: $benefitsSourceDir } }
        ) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }

        content_images: allFile(
            filter: { sourceInstanceName: { eq: "products_images" }, relativeDirectory: { eq: $contentSourceDir } }
        ) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }

        download_app_image: file(
            sourceInstanceName: { eq: "products_images" }
            relativeDirectory: { eq: $downloadAppSourceDir }
        ) {
            publicURL
        }
    }
`
