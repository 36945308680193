import React, { useEffect, createRef } from "react"
import "./styles.scss"
import ButtonsGroup from "../../DynamicButtonsComponent"
import MailForm from "../../MailForm"

import lottie from "lottie-web"

function DynamicComponent({ component_type, component_props, theme }) {
    let Component = <span />

    if (component_type === "BUTTONS") {
        Component = <ButtonsGroup {...component_props} />
    } else if (component_type === "EMAIL_FEEDBACK_FORM") {
        Component = <MailForm {...component_props} />
    }

    return Component
}

export default function Home({ description, title, image, theme, component_type, component_props, preview_device }) {
    const animationContainer = createRef()

    const previewMediaClass = preview_device.animation_type === "lottie" ? "show-lottie-on-desktop" : ""

    useEffect(() => {
        let animation = {}

        if (preview_device.animation_json) {
            animation = lottie.loadAnimation({
                container: animationContainer.current,
                animationData: JSON.parse(preview_device.animation_json),
                renderer: "svg",
                loop: true,
                autoplay: false,
            })

            animation.play()
        }

        const wistia = document.createElement("script")
        wistia.async = true
        wistia.src = "https://fast.wistia.com/assets/external/E-v1.js"

        const calendarVideo = document.createElement("script")
        calendarVideo.async = true
        calendarVideo.src = "https://fast.wistia.com/embed/medias/4x6067ojmc.jsonp"

        document.head.appendChild(calendarVideo)
        document.head.appendChild(wistia)

        return () => animation.hasOwnProperty("destroy") && animation.destroy()
    }, [])

    return (
        <header className={`product-header-wrapper ${theme} ${previewMediaClass}`}>
            <div className="product-header-container">
                <div className={"product-header-container__preview-box"}>
                    <div className={"product-header-container__lottie"} ref={animationContainer} />
                </div>

                <div className={"product-header-container__text-box-wrapper"}>
                    <div className={"product-header-container__text-box"}>
                        <p className={"product-title"}>{title}</p>
                        <p className={"product-description"}>{description}</p>
                        <div>
                            <DynamicComponent
                                component_type={component_type}
                                theme={theme}
                                component_props={component_props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
