import React from "react"
import "./styles.scss"
import { emitAnalyticsEvent } from "../../analytics"

const AppleIcon = () => (
    <svg
        className={"hide-on-mobile"}
        xmlns="http://www.w3.org/2000/svg"
        width="17.455"
        height="21.359"
        viewBox="0 0 17.455 21.359"
    >
        <path
            d="M28.776 75.107c-1.806 3.134-.658 7.893 1.339 10.772 1 1.441 2.006 2.735 3.388 2.735h.079a4.184 4.184 0 0 0 1.572-.419 4.306 4.306 0 0 1 1.863-.442 4.049 4.049 0 0 1 1.773.427 3.756 3.756 0 0 0 1.7.407c1.557-.029 2.513-1.425 3.356-2.657a11.777 11.777 0 0 0 1.47-3.012l.006-.019a.175.175 0 0 0-.093-.215l-.013-.005a4.463 4.463 0 0 1-2.7-4.087 4.594 4.594 0 0 1 2.171-3.84l.017-.011a.175.175 0 0 0 .05-.246 5.052 5.052 0 0 0-3.973-2.158c-.113-.011-.23-.017-.347-.017a6.927 6.927 0 0 0-2.494.623 4.009 4.009 0 0 1-1.195.357 3.908 3.908 0 0 1-1.236-.361 5.988 5.988 0 0 0-2.178-.553h-.055a5.34 5.34 0 0 0-4.5 2.721z"
            transform="translate(-27.875 -67.256)"
        />
        <path
            d="M153.614 0a4.877 4.877 0 0 0-3.22 1.651 4.569 4.569 0 0 0-1.157 3.388.175.175 0 0 0 .16.152c.075.006.151.009.226.009a4.125 4.125 0 0 0 3.039-1.559A4.817 4.817 0 0 0 153.8.156a.176.176 0 0 0-.186-.156z"
            transform="translate(-140.713)"
        />
    </svg>
)

const PlayStoreIcon = () => (
    <svg
        className={"hide-on-mobile"}
        xmlns="http://www.w3.org/2000/svg"
        width="17.44"
        height="20.884"
        viewBox="0 0 17.44 20.884"
    >
        <g transform="translate(-706.662 -693.799)">
            <path
                d="M9.593 19.577v-3.053H7.847v3.053a1.307 1.307 0 0 1-2.615 0v-3.053H4.36a.825.825 0 0 1-.873-.873v-8.72h10.466v8.72a.825.825 0 0 1-.873.873h-.873v3.053a1.307 1.307 0 0 1-2.615 0zm5.233-5.233V8.238a1.307 1.307 0 0 1 2.615 0v6.105a1.307 1.307 0 1 1-2.615 0zM0 14.344V8.238a1.341 1.341 0 0 1 1.307-1.307 1.341 1.341 0 0 1 1.307 1.307v6.105a1.339 1.339 0 0 1-1.307 1.307A1.339 1.339 0 0 1 0 14.344zm3.487-8.286c0-.079 0-.158.006-.236v-.036a5.124 5.124 0 0 1 1.188-3.03 4.723 4.723 0 0 1 .984-.883L4.535.74a.419.419 0 0 1 0-.608.418.418 0 0 1 .609 0L6.314 1.3l.054.054A5.162 5.162 0 0 1 8.707.83h.026a5.163 5.163 0 0 1 2.339.523l.054-.054L12.3.132a.418.418 0 0 1 .609 0 .419.419 0 0 1 0 .608l-1.136 1.133a4.72 4.72 0 0 1 .983.883 5.125 5.125 0 0 1 1.189 3.03v.036c0 .079.006.157.006.236zm6.761-2.178a.654.654 0 1 0 .654-.654.654.654 0 0 0-.654.654zm-4.363 0a.654.654 0 1 0 .654-.654.654.654 0 0 0-.655.654z"
                transform="translate(706.662 693.799)"
            />
        </g>
    </svg>
)

const PlayIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 320.001 320.001">
        <path
            d="M295.84,146.049l-256-144c-4.96-2.784-11.008-2.72-15.904,0.128C19.008,5.057,16,10.305,16,16.001v288
      c0,5.696,3.008,10.944,7.936,13.824c2.496,1.44,5.28,2.176,8.064,2.176c2.688,0,5.408-0.672,7.84-2.048l256-144
      c5.024-2.848,8.16-8.16,8.16-13.952S300.864,148.897,295.84,146.049z"
        />
    </svg>
)

function DynamicButtonsComponent({ type, label, href, actionType, categoryType, ...props }) {
    const handleBtnClick = () => {
      if (actionType && categoryType) {
            emitAnalyticsEvent(actionType, categoryType)
        }
    }

    if (type === "PlayStoreButton") {
        return (
            <span onClick={handleBtnClick}>
                <a href={href} target={"_blank"}>
                    <button {...props} className={`a-button`}>
                        <PlayStoreIcon />
                        <span>{label}</span>
                    </button>
                </a>
            </span>
        )
    } else if (type === "AppStoreButton") {
        return (
            <span onClick={handleBtnClick}>
                <a href={href} target={"_blank"}>
                    <button {...props} className={`a-button`}>
                        <AppleIcon />
                        <span>{label}</span>
                    </button>
                </a>
            </span>
        )
    } else if (type === "PlayButton") {
        return (
            <span {...props}  onClick={handleBtnClick} className={`a-button default-btn`}>
                <span className="a-play-circle">
                    <PlayIcon />
                </span>
                <span className={`wistia-button wistia_embed ${href} popover=true popoverAnimateThumbnail=true`} />
                <span className={"hide-on-mobile"}>{label}</span>
            </span>
        )
    }
}

export default function ButtonsGroup({ buttons, theme = "" }) {
    return (
        <>
            <div className={`a-button-group ${theme}`}>
                {buttons.map(({ type, ...buttonData }, i) => (
                    <DynamicButtonsComponent type={type} {...buttonData} key={i} />
                ))}
            </div>
        </>
    )
}
