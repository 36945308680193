import React from "react"
import { useSectionObserver } from "../../../hoc/useSectionObserver"
import "./styles.scss"

import ButtonsGroup from "../../DynamicButtonsComponent"
import MailForm from "../../MailForm"

// TODO: DRY!
function DynamicComponent({ component_type, component_props, theme }) {
    let Component = <span />

    if (component_type === "BUTTONS") {
        Component = <ButtonsGroup {...component_props} theme={theme} />
    } else if (component_type === "EMAIL_FEEDBACK_FORM") {
        Component = <MailForm {...component_props} theme={theme} />
    }

    return Component
}

function DownloadAppPreviewImage({ src, inView }) {
    const [activeClass, setActiveClass] = React.useState("")

    React.useEffect(() => {
        if (inView && !activeClass) {
            setActiveClass("download-image-slide-up")
        }
    }, [inView])

    return src ? (
        <div className={`download-app-preview ${activeClass}`}>
            <img src={src} alt="preview" />
        </div>
    ) : null
}

export default function DownloadAppBlock({
    image,
    title,
    subtitle,
    description,
    component_type,
    component_props,
    theme,
}) {
    const imagePublicURL = image && image.publicURL
    const { inView, ref } = useSectionObserver()

    return (
        <div className={`download-app-section-container ${theme}`} ref={ref}>
            <div className="download-app-section-container__box">
                <div className={`download-app-body ${!imagePublicURL && "align-center-body"}`}>
                    <p className={"download-app-title"}>{title}</p>
                    {subtitle && <p className={"download-app-subtitle"}>{subtitle}</p>}
                    <p className={"download-app-description"}>{description}</p>

                    <DynamicComponent
                        component_type={component_type}
                        component_props={component_props}
                        theme={"light-colors"}
                    />
                </div>

                <DownloadAppPreviewImage inView={inView} src={imagePublicURL} />
            </div>
        </div>
    )
}
