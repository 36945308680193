import React from "react"
import "./styles.scss"

function BenefitItem({ description, icon, title }) {
    return (
        <div className={"benefit-box text-center"}>
            <img src={icon} alt={title} />
            <p className={"product-subtitle"}>{title}</p>
            <p className={"product-description"}>{description}</p>
        </div>
    )
}

export default function Benefits({ title, items, iconsSrc }) {
    return (
        <div className={"benefits-container"}>
            <div className={"benefits-container__title-wrapper"}>
                <p className={"product-title text-center"}>{title}</p>
            </div>
            <div className={"benefits-wrapper"}>
                {items.map(({ icon_name, ...benefit }, i) => {
                    const icon = iconsSrc.find((iconData) => iconData.node.name === icon_name)
                    return <BenefitItem {...benefit} icon={icon.node.publicURL} key={i} />
                })}
            </div>
        </div>
    )
}
