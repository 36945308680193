import React from "react"
import "./styles.scss"
import starSrc from "../../assets/images/icons/star.svg"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

function Rating({ value }) {
    return new Array(value).fill(null).map((_, i) => (
        <div className={"star-size"} key={i}>
            <img src={starSrc} alt="" />
        </div>
    ))
}

function Author({ name, avatar, position, company_name }) {
    return (
        <div className={"author-box"}>
            <div className="author-box__avatar-wrapper">
                <GatsbyImage fixed={avatar} />
            </div>

            <div className="author-box__body-wrapper">
                <p className={"author-name"}>{name}</p>
                <p className={"author-position"}>
                    <span>{position}</span> <span className="author-company-name">{company_name}</span>
                </p>
            </div>
        </div>
    )
}

function FeedbackCard({ rating, message, author }) {
    return (
        <div className="feedback-slide-wrapper">
            <div className={"feedback-card-container"}>
                <div className={"feedback-card-container__rating-wrapper"}>
                    <Rating value={rating} />
                </div>
                <div className={"feedback-card-container__message-wrapper"}>{message}</div>
                <div className="feedback-card-container__author-wrapper">
                    <Author {...author} />
                </div>
                <div className="feedback-card-container__hover-indicator" />
            </div>
        </div>
    )
}

const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"dot"} />,
}

export default function ClientsFeedback({ title, items, theme }) {
    const { clientsImages } = useStaticQuery(graphql`
        query clientsImagesQuery {
            clientsImages: allFile(
                filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "clients" } }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fixed(cropFocus: CENTER, width: 53, height: 53) {
                                src
                                width
                                srcWebp
                                aspectRatio
                                height
                                srcSet
                            }
                        }
                    }
                }
            }
        }
    `)
    if (!items) {
        return null
    } else {
        const clients = items.reduce((memo, client) => {
            const {
                author: { avatar, ...authorData },
                ...feedbackInfo
            } = client
            let clientWithAvatar = {}

            clientsImages.edges.forEach((image) => {
                const { name } = image.node

                if (avatar === name) {
                    clientWithAvatar = {
                        author: {
                            ...authorData,
                            avatar: image.node.childImageSharp.fixed,
                        },
                        ...feedbackInfo,
                    }
                }
            })

            return memo.concat(clientWithAvatar)
        }, [])
        return (
            <div className={`client-feedback-fluid-container ${theme}`}>
                <div className={`client-feedback-container`}>
                    <div className={"client-feedback-title"}>
                        <p className="product-title text-center">{title}</p>
                    </div>

                    <div className={"feedback-desktop"}>
                        {clients.map((feedback) => (
                            <FeedbackCard {...feedback} key={feedback.id} />
                        ))}
                    </div>

                    <div className="feedback-mobile">
                        <Slider {...settings}>
                            {clients.map((feedback) => (
                                <FeedbackCard {...feedback} key={feedback.id} />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}
